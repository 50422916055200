import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import Links from "./Links";
import SocialMedia from "../Component/Components/SocialMedia";
// import Links from './Link';
const Header = () => {
  return (
    <>
      <div className="header">

        <Navbar collapseOnSelect expand="lg">
          <Navbar.Brand href="#home" className="logo">
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end nav-menu">
            <img src="/logo.jpg" alt="GID" className="rounded-circle" />
            <SocialMedia />
            <Nav className="ms-auto font">
              <Links href="#Home">Home</Links>
              <Links href="#About">About us</Links>
              <Links href="#Founder">Founder</Links>
              <Links href="#Experience">Courses</Links>
              <Links href="#Consumer">Lab Facility</Links>
              <Links href="#Brands">Brands</Links>
              <Links href="#Potfolio">Gallery</Links>
              <Links href="#Contact">Contact</Links>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </>
  );
};

export default Header;
