import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'

const Brands = () => {
    return (
        <>
            <>
                <div className="container" id="Brands">
                    <div className="about headding " data-aos="fade-right">
                        <div>
                            <br /><br /><br /><h1 className="about-title" style={{ textAlign: "left" }}>Our Brands</h1>
                            <br /><br />
                            <div>
                                <form className="row g-3 needs-validation">
                                    <div className="form-control">
                                        JEWELS AND AYK JEWELS
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    )
}

export default Brands