export let clarity = JSON.parse(localStorage.getItem('clarity'))
export let color = JSON.parse(localStorage.getItem('color'))
export let comments = JSON.parse(localStorage.getItem('comments'))
export let cut = JSON.parse(localStorage.getItem('cut'))
export let description = JSON.parse(localStorage.getItem('description'))
export let estimatedWeight = JSON.parse(localStorage.getItem('estimatedWeight'))
export let extraComments = JSON.parse(localStorage.getItem('extraComments'))
export let finish = JSON.parse(localStorage.getItem('finish'))
export let girdle = JSON.parse(localStorage.getItem('girdle'))
export let luster = JSON.parse(localStorage.getItem('luster'))
export let making = JSON.parse(localStorage.getItem('making'))
export let maximumApproxYield = JSON.parse(localStorage.getItem('maximumApproxYield'))
export let mountedDiamondWeight = JSON.parse(localStorage.getItem('mountedDiamondWeight'))
export let orient = JSON.parse(localStorage.getItem('orient'))
export let polish = JSON.parse(localStorage.getItem('polish'))
export let rom = JSON.parse(localStorage.getItem('rom'))
export let shape = JSON.parse(localStorage.getItem('shape'))
export let shapeAndCut = JSON.parse(localStorage.getItem('shapeAndCut'))
export let symmetry = JSON.parse(localStorage.getItem('symmetry'))
export let transparency = JSON.parse(localStorage.getItem('transparency'))
export let uvFluorescence = JSON.parse(localStorage.getItem('uvFluorescence'))