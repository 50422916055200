import React, { useEffect, useState } from "react";

const UC_Select = (props) => {

  const [focused, setFocused] = useState(false);
  const [listItems, setListItems] = useState([]);
  useEffect(() => {
    setListItems(props.bindList);
  })

  const { errorMessage, onChange, id, bindList, ...inputProps } = props;
  const handleFocus = (e) => {
    setFocused(true);
  };

  return <>  
    <select
      onBlur={handleFocus}
      onChange={onChange}
      className="form-select"
      focused={focused.toString()}
      {...inputProps}>
      <option value="" disabled selected hidden>{`Select-${props.title}`}</option>
      {listItems && listItems.sort((a, b) => a.Id - b.Id).map(result => (<option text={result.Id}>{result.BindField}</option>))}
    </select>
    <span>{errorMessage}</span>
  </>
}
export default UC_Select;