import { Route, Switch } from 'react-router-dom';
import React from 'react'
import dashBoard from "./AppDashboard"
import DiamondGrading from "../Certificates/CertDiamondGrading"
import Gem from "../Certificates/CertGem"
import MountedJewelry from "../Certificates/CertMountedJewelry"
import MountedJewelryGem from "../Certificates/CertMountedJewelryGem"
import PearlGrading from "../Certificates/CertPearlGrading"
import RoughDiamond from "../Certificates/CertRoughDiamond"
import List from "../Certificates/CertList"
import DiamondGradingCertPrint from "../PrintTemplate/DiamondGradingPrint"


export default function AppContent() {

  return (
    <main className="mt-4 background">
      <div className="container-fluid border title-padding-top">
        <Switch>          
          <Route path='/dashboard' exact component={dashBoard} />
          <Route path='/dashboard/DiamondGrading' exact component={DiamondGrading} />
          <Route path='/dashboard/gem' exact component={Gem} />
          <Route path='/dashboard/MountedJewelry' exact component={MountedJewelry} />
          <Route path='/dashboard/mountedJewelryGem' exact component={MountedJewelryGem} />
          <Route path='/dashboard/pearlGrading' exact component={PearlGrading} />
          <Route path='/dashboard/roughDiamond' exact component={RoughDiamond} />
          <Route path='/dashboard/list' exact component={List} />
          <Route path='/dashboard/DiamondCert' exact component={DiamondGradingCertPrint} />
        </Switch>
      </div>
    </main>
  )
}
