import React, { useState } from 'react'
import ReactCalender from '../Utility/utilityReactCalender'
import { useHistory } from 'react-router-dom'
import TextInput from "../InputControls/UC_Input";
import SelectControl from "../InputControls/UC_Select"
import * as LocalStorage from "../ApiCall/LocalStorage";
import { addRoughDiamond, uploadImage } from "../ApiCall/Crud";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../Utility/Loader'
import emptyFileIcon from '../../assets/Images/emptyFile.png'
import imgSig from '../../assets/Images/sigGreen.png';
import { genCertNo } from '../Utility/genCertNo';


export default function CertRoughDiamond() {
  const selectInputs = [
    {
      id: 1,
      name: "Description",
      type: "select",
      placeholder: "Description",
      errorMessage: "Select Description.",
      required: false,
      bindList: LocalStorage.description,
    },
    {
      id: 2,
      name: "Color",
      type: "select",
      placeholder: "Color",
      errorMessage: "Select Color.",
      required: false,
      bindList: LocalStorage.color,
    },
    {
      id: 3,
      name: "MaximumYield",
      type: "select",
      placeholder: "Maximum/Yield %",
      errorMessage: "Select Maximum/Yield %.",
      required: false,
      bindList: LocalStorage.maximumApproxYield,
    },
    {
      id: 4,
      name: "UVFluorescence",
      type: "select",
      placeholder: "UV Fluorescence",
      errorMessage: "Select UV Fluorescence.",
      required: false,
      bindList: LocalStorage.uvFluorescence,
    },
    {
      id: 5,
      name: "Rom",
      type: "select",
      placeholder: "Rom",
      errorMessage: "Select Rom.",
      required: false,
      bindList: LocalStorage.rom,
    }
  ];
  const inputs = [
    {
      id: 1,
      name: "CustomerName",
      type: "text",
      placeholder: "Customer Name",
      errorMessage: "Enter Customer Name",
      pattern: "[a-zA-Z][a-zA-Z ]{4,}",
      required: false,
    }, {
      id: 2,
      name: "CertificateNumber",
      type: "text",
      placeholder: "Certificate Number",
      errorMessage: "Enter Certificate Number",
      required: false,
      disabled: true
    },
    {
      id: 3,
      name: "CaratWeight",
      type: "text",
      placeholder: "Carat Weight",
      errorMessage: "Enter Carat Weight"
    },
    {
      id: 4,
      name: "Comments",
      type: "text",
      placeholder: "Carat Comments",
      errorMessage: "Enter Comments",
      required: false,
    },
    {
      id: 5,
      name: "SelectImage",
      type: "file",
      placeholder: "Upload Image",
      errorMessage: "Upload Image",
      required: false,
    },
  ];
  const [fileData, setFileData] = useState();
  const [values, setValues] = useState({});
  const [selectValues, setSelectValues] = useState({});
  const [isLoader, setLoader] = useState(false);
  const [imagePreview, setImagePreview] = useState(emptyFileIcon);;
  const [printType, setPrintType] = useState('paper'); const [client, setClient] = useState();
  let history = useHistory();
  var standardDate = new Date();
  if (values.CertificateNumber == null) values.CertificateNumber = 'RD' + genCertNo.call();

  const onSelectChange = (e) => {
    setSelectValues({ ...selectValues, [e.target.name]: e.target.value });
  };
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const [valueDate, setDateValues] = useState({});
  const onDateChange = (e) => {
    setDateValues({ ...valueDate, ["selectedDate"]: e.target.value });
  }
  // const addCertificate = async (e) => {
  //   e.preventDefault();
  //   var printContents = null;
  //   if (printType == "paperPrint")
  //     printContents = document.getElementById("divToPaperPrint").innerHTML;
  //   else printContents = document.getElementById("divToCardPrint").innerHTML;
  //   document.body.innerHTML = printContents;
  //   window.print();
  //   window.location.reload(); return;
  // }
  const addCertificate = async (e) => {
    e.preventDefault();
    document.getElementById("btnClosePreview").click();
    setLoader(true)
    const data = new FormData();
    data.append('image', fileData)
    var printContents = null;
    if (printType == "paperPrint")
      printContents = document.getElementById("divToPaperPrint").innerHTML;
    else printContents = document.getElementById("divToCardPrint").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    window.location.reload(); return;
    // uploadImage(data).then((fileName) => {
    //   addRoughDiamond(values.CustomerName, valueDate.selectedDate, values.CertificateNumber, "3", selectValues.Description, values.CaratWeight, selectValues.Color, selectValues.MaximumYield, selectValues.UVFluorescence, selectValues.Rom, values.Comments, fileName.file, "", client).then((result) => {
    //     const { status, statusText } = result
    //     // if (status === 401 || statusText === 'unauthorized') {
    //     //   setLoader(false)
    //     //   window.alert("Please login, your session is expire.")
    //     //   history.push("/Login")
    //     // }
    //     if (status === 200) {
    //       setLoader(false)
    //       toast("Rough Diamond Certificate Crated Successfully.", {
    //         autoClose: 1000,
    //         onClose: () => {
    //           var printContents = null;
    //           if (printType == "paperPrint")
    //             printContents = document.getElementById("divToPaperPrint").innerHTML;
    //           else printContents = document.getElementById("divToCardPrint").innerHTML;
    //           var originalContents = document.body.innerHTML;
    //           document.body.innerHTML = printContents;
    //           window.print();
    //           window.location.reload(); return;
    //         }
    //       });
    //     }
    //     else {
    //       setLoader(false)
    //       toast("error in add!", { autoClose: 2000 });
    //     }
    //   })
    // }).catch((error) => {
    //   setLoader(false);
    //   toast("error in upload!", { autoClose: 2000 })
    // })

  }


  const onImageChange = (e) => {
    e.preventDefault();
    setFileData(e.target.files[0])
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  }
  const onClear = (e) => {
    e.preventDefault();
    window.location.reload();
  }
  const onPrintTypeClick = (e) => {
    setPrintType(e.target.value)
  }
  const onClientChange = (e) => {
    e.preventDefault();
    setClient(e.target.value)
  }
  return (
    <>
      <form enctype="multipart/form-data" onSubmit={addCertificate}>
        <div className="row p-1">
          <div className="text-muted fw-bold text-uppercase mt-2 text-center">
            Rough Diamond Certificate
          </div>
        </div>
        <hr className="dropdown-divider bg-dark mb-1" />
        <div className="row p-1">
          <div className="form-group col-sm-6 flex-column d-flex"> <label className="form-control-label" >Customer Name<span className="text-danger"> *</span></label>
            <TextInput
              key={inputs[0].id}
              {...inputs[0]}
              value={values.CustomerName}
              onChange={onChange}
            /> </div>
          <div className="form-group col-sm-6 flex-column d-flex"> <label className="form-control-label">Select Date<span className="text-danger"> *</span></label>
            <ReactCalender className="date"
              value={valueDate}
              onChange={onDateChange} />

          </div>
        </div>
        <div className='row p-1'>
          <div className="form-group col-sm-6 flex-column d-flex"> <label className="form-control-label" >Certificate No<span className="text-danger"> *</span></label>
            <input type="text" value={values.CertificateNumber} disabled />
          </div>
          <div className="form-group col-sm-6 flex-column d-flex"> <label className="form-control-label" >Description<span className="text-danger"> *</span></label>

            <SelectControl
              key={selectInputs[0].id}
              {...selectInputs[0]}
              value={selectValues.Description}
              title="Description"
              onChange={onSelectChange}
            />
          </div>
        </div>
        <div className="row p-1">
          <div className="form-group col-sm-6 flex-column d-flex">
            <label className="form-control-label" >Carat Weight<span className="text-danger"> *</span></label>
            <TextInput
              key={inputs[2].id}
              {...inputs[2]}
              value={values.CaratWeight}
              onChange={onChange}
            />
          </div>
          <div className="form-group col-sm-6 flex-column d-flex"> <label className="form-control-label" >Color<span className="text-danger"> *</span></label>

            <SelectControl
              key={selectInputs[1].id}
              {...selectInputs[1]}
              value={values.Color}
              title="Color"
              onChange={onSelectChange}
            />
          </div>
        </div>
        <div className="row p-1">
          <div className="form-group col-sm-6 flex-column d-flex"> <label className="form-control-label" >Maximum/Approx/Yield<span className="text-danger"> *</span></label>
            <SelectControl
              key={selectInputs[2].id}
              {...selectInputs[2]}
              value={values.MaximumYield}
              title="Maximum/Approx/Yield"
              onChange={onSelectChange}
            />
          </div>
          <div className="form-group col-sm-6 flex-column d-flex"> <label className="form-control-label" >UV Fluorescence<span className="text-danger"> *</span></label>

            <SelectControl
              key={selectInputs[3].id}
              {...selectInputs[3]}
              value={values.UVFluorescence}
              title="UVFluorescence"
              onChange={onSelectChange}
            />
          </div>
        </div>
        <div className="row p-1">
          <div className="form-group col-sm-6 flex-column d-flex"> <label className="form-control-label" >Rom<span className="text-danger"> *</span></label>
            <SelectControl
              key={selectInputs[4].id}
              {...selectInputs[4]}
              value={values.Rom}
              title="Rom"
              onChange={onSelectChange}
            />
          </div>
          <div className="form-group col-sm-6 flex-column d-flex">
            <label className="form-control-label" >Comments<span className="text-danger"> *</span></label>
            <TextInput
              key={inputs[3].id}
              {...inputs[3]}
              value={values.Comments}
              onChange={onChange}
            />
          </div>
          <div className="row p-1">
            <div className="form-group col-sm-6 flex-column d-flex"> <label className="form-control-label" >For<span className="text-danger"> *</span></label>
              <input type='text' placeholder='Certificate For'
                value={client} maxLength='15'
                onChange={onClientChange}
              />
            </div>
          </div>
        </div>
        <div className="row p-1">
          <div className="form-group col-sm-6 flex-column d-flex">
            <div>
              <div className='bar'>
                <button type="button" className="send-btn button float-start m-1" data-bs-toggle="modal" data-bs-target="#exampleModal">
                  Preview
                </button>
              </div>
              <div className='bar'>
                <button className='send-btn button float-start m-1' onClick={onClear} type="submit">Clear</button>
              </div>
              <div className='bar' style={{ marginTop: '2px' }}>{isLoader && <Loader />}</div>
              <ToastContainer />
            </div>
          </div>
          <div className="form-group col-sm-6 flex-column d-flex">
            <div>
              <div className='bar'>
                <img alt="..." style={{ width: 100, height: 100, border: '1px solid #555', borderRadius: 10 }}
                  src={imagePreview}></img>
              </div>
              <div className='bar'>
                <input style={{ float: 'right' }} type="file" onChange={onImageChange} class="form-control-file" name="myFile" accept="image/png, image/gif, image/jpeg" required="true" />
              </div>
              <div className="form-check form-check-inline" style={{ marginTop: '30px' }}>
                <input type="radio" name="printType" defaultValue="paperPrint" onClick={onPrintTypeClick} required />
                <label className="form-check-label" value="paper">Paper Certificate</label>
              </div>
              <div className="form-check form-check-inline" style={{ marginTop: '30px' }}>
                <input type="radio" name="printType" defaultValue="cardPrint" onClick={onPrintTypeClick} required />
                <label className="form-check-label" value="card">Card Certificate </label>
              </div>
            </div>
          </div>
        </div>

        <div id="divToPaperPrint" style={{ display: 'none' }}>
          <div style={{ float: 'left', marginTop: '37px', fontSize: '10px', marginLeft: '25px', width: '280px' }}>
            <h6 style={{ marginLeft: 120, fontWeight: 'bold', fontSize: '12px', width: '270px', marginBottom: '10px' }}>
              <u>ROUGH DIAMOND CERTIFICATE</u>
            </h6>
            <table className='tb'>
              <tr>
                <td>Certificate No.</td>
                <td>:</td>
                <td width={200}>{values.CertificateNumber}</td>
              </tr>
              <tr>
                <td>Description</td>
                <td>:</td>
                <td>{selectValues.Description} </td>
              </tr>
              <tr>
                <td>Rom</td>
                <td>:</td>
                <td>{selectValues.Rom} </td>
              </tr>
              <tr>
                <td>Carat Weight</td>
                <td>:</td>
                <td>{values.CaratWeight}</td>
              </tr>
              <tr>
                <td>Color</td>
                <td>:</td>
                <td>{selectValues.Color}</td>
              </tr>
              <tr>
                <td>Maximum/Approx/Yield</td>
                <td>:</td>
                <td>{selectValues.MaximumYield}</td>
              </tr>
              <tr>
                <td>UV Fluorescence</td>
                <td>:</td>
                <td>{selectValues.UVFluorescence}</td>
              </tr>
              <tr>
                <td>Comments</td>
                <td>:</td>
                <td>{values.Comments}</td>
              </tr>  <tr>
                <td style={{ verticalAlign: 'top' }}>For</td>
                <td>:</td>
                <td>{client}</td>
              </tr>
            </table>
          </div>
          <div style={{ marginLeft: 5, fontSize: 12 }} >
            <div style={{ float: 'left', marginLeft: '70px', marginTop: '62px' }}>
              <img alt="..." style={{ width: 88, height: 88, border: '1px solid #555', borderRadius: 10 }} src={imagePreview}></img>
            </div>
            <div style={{ textAlign: 'center', marginLeft: -90, marginTop: 175, fontSize: 10, float: 'left', fontWeight: 'bold' }}>
              <div className='containerImg'>
                <img src={imgSig} style={{ width: 92, height: 60, paddingBottom: "5px" }} />
                <div className='content'>
                  <span>IMRAN SHAREEF</span> <br />
                  <span>Gemologist</span> <br />
                  <span>GIA&#46;&nbsp;USA</span><br />
                  <sp>
                    (D):&nbsp;{new Date(valueDate.selectedDate).getDate()}&#8211;
                    {new Date(valueDate.selectedDate).getMonth() + 1}&#8211;
                    {new Date(valueDate.selectedDate).getFullYear()}
                  </sp>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="divToCardPrint" style={{ display: 'none' }}>
          <div style={{ float: 'left', marginTop: '15px', marginLeft: '110px', width: '150px', fontSize: '8px' }}>
            <h6 style={{ marginLeft: 55, fontWeight: 'bold', width: '160px', fontSize: '9px', marginBottom: '10px' }}>
              <u>ROUGH DIAMOND CERTIFICATE</u>
            </h6>
            <table className='tb-card'>
              <tr>
                <td>Certificate No.</td>
                <td>:</td>
                <td width={200}>{values.CertificateNumber}</td>
              </tr>
              <tr>
                <td>Description</td>
                <td>:</td>
                <td>{selectValues.Description} </td>
              </tr>
              <tr>
                <td>Rom</td>
                <td>:</td>
                <td>{selectValues.Rom} </td>
              </tr>
              <tr>
                <td>Carat Weight</td>
                <td>:</td>
                <td>{values.CaratWeight}</td>
              </tr>
              <tr>
                <td>Color</td>
                <td>:</td>
                <td>{selectValues.Color}</td>
              </tr>
              <tr>
                <td>Maximum/Approx/Yield</td>
                <td>:</td>
                <td>{selectValues.MaximumYield}</td>
              </tr>
              <tr>
                <td>UV Fluorescence</td>
                <td>:</td>
                <td>{selectValues.UVFluorescence}</td>
              </tr>
              <tr>
                <td>Comments</td>
                <td>:</td>
                <td>{values.Comments}</td>
              </tr>  <tr>
                <td style={{ verticalAlign: 'top' }}>For</td>
                <td>:</td>
                <td>{client}</td>
              </tr>
            </table>
          </div>
          <div>
            <div style={{ float: 'left', marginLeft: '58px', marginTop: '35px' }}>
              <img alt="..." style={{ width: 60, height: 60, border: '1px solid #555', borderRadius: 10 }} src={imagePreview}></img>
            </div>
            <div style={{ textAlign: 'center', marginLeft: -60, marginTop: 120, float: 'left', fontWeight: 'bold', fontSize: '8px' }}>
              <div className='containerImg'>
                <img src={imgSig} style={{ width: 73, height: 50, paddingBottom: "5px" }} />
                <div className='content'>
                  <span>IMRAN SHAREEF</span> <br />
                  <span>Gemologist</span> <br />
                  <span>GIA&#46;&nbsp;USA</span><br />
                  <sp>
                    (D):&nbsp;{new Date(valueDate.selectedDate).getDate()}&#8211;
                    {new Date(valueDate.selectedDate).getMonth() + 1}&#8211;
                    {new Date(valueDate.selectedDate).getFullYear()}
                  </sp>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" style={{ width: '360px' }}>
            <div className="modal-content">
              <div>
                <div className="row p-1">
                  <div className="text-muted fw-bold text-uppercase text-center text-decoration-underline" style={{ height: '25px' }}>
                    Print Preview - Rough Diamond
                  </div>
                </div>
                <table className='tb-printPre' style={{ fontSize: '12px', marginLeft: '13px' }}>
                  <tr>
                    <td>Certificate No.</td>
                    <td>:</td>
                    <td>
                      <input type="text" value={values.CertificateNumber} disabled /></td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td>:</td>
                    <td>
                      <input
                        key={selectInputs[0].id}
                        {...selectInputs[0]}
                        value={selectValues.Description}
                        title="Description"
                        onChange={onSelectChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Rom</td>
                    <td>:</td>
                    <td> <input type="text"
                      key={selectInputs[4].id}
                      {...selectInputs[4]}
                      value={selectValues[selectInputs[4].name]}
                      title="Rom"
                      onChange={onSelectChange}
                    /></td>
                  </tr>
                  <tr>
                    <td>Carat Weight</td>
                    <td>:</td>
                    <td> <input type="text"
                      key={inputs[2].id}
                      {...inputs[2]}
                      value={values[inputs[2].name]}
                      onChange={onChange}
                    /></td>
                  </tr>
                  <tr>
                    <td>Color</td>
                    <td>:</td>
                    <td><input type="text"
                      key={selectInputs[1].id}
                      {...selectInputs[1]}
                      value={selectValues[selectInputs[1].name]}
                      title="Color"
                      onChange={onSelectChange}
                    />
                    </td>
                  </tr>
                  <tr>
                    <td>Maximum/Approx/Yield</td>
                    <td>:</td>
                    <td><input type="text"
                      key={selectInputs[2].id}
                      {...selectInputs[2]}
                      value={selectValues[selectInputs[2].name]}
                      onChange={onSelectChange}
                    /></td>
                  </tr>
                  <tr>
                    <td>UV Fluorescence</td>
                    <td>:</td>
                    <td><input type="text"
                      key={selectInputs[3].id}
                      {...selectInputs[3]}
                      value={selectValues[selectInputs[3].name]}
                      title="UVFluorescence"
                      onChange={onSelectChange}
                    /></td>
                  </tr>
                  <tr>
                    <td>Comments</td>
                    <td>:</td>
                    <td> <input type="text"
                      key={inputs[3].id}
                      {...inputs[3]}
                      value={values[inputs[3].name]}
                      onChange={onChange}
                    /></td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: 'top' }}>For</td>
                    <td>:</td>
                    <td>
                      <input type='text' placeholder='Certificate For'
                        value={client} maxLength='15'
                        onChange={onClientChange}
                      />
                    </td>
                  </tr>
                </table>
              </div>
              <div className="row p-1">
                <hr />
                <div className="text-muted fw-bold text-uppercase text-center" style={{ marginTop: '-12px' }}>
                  <img alt="..." style={{ width: 40, height: 40, border: '1px solid #555', borderRadius: 10, marginRight: '180px' }}
                    src={imagePreview}></img>

                  <button type="Submit" className='send-btn button float-left m-1' type="submit">Add</button>
                  <button type="button" id='btnClosePreview' className="send-btn button float-left m-1"
                    data-bs-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}