import React, { useEffect } from "react";
import Banner from "../../../assets/Images/Banner/Banner.jpg";
import Aos from "aos";
import "aos/dist/aos.css";
const HeaderBanner = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div id="Home">
        <div>
          <span>
            <div>
              <img style={{opacity:'0.2', backgroundColor:'rgba(54, 25, 25, .00004)'}}
                src={Banner}
                className="img-fluid  mx-auto width: 100%"
                alt="Banner"
              ></img> 
              {/* source:wallpaperflare */}
            </div>
          </span>
          <div style={{fontFamily:'Sacramento', color:'gray'}} className="img-bnr-text">
            <div>
              <h5  style={{fontFamily:'Allura'}} data-aos="fade-down">GEM TESTING <br />LAB &#38; LAPIDARY</h5>

              <h1  data-aos="flip-down">Golconda Institute Of Diamonds</h1>
              <p style={{fontFamily:'Allura'}} data-aos="fade-up">
                Golconda Institute of Diamonds, first of its kind in our state                
                in the heart of the city.
                Its mission is to ensure in Gem and jewelry Industry by
                upholding the highest standard of professionalism through
                Laboratory Services &#40; Gem trade lab &#41; Lapidary
                &#40;Diamond Cutting and Polishing &#41; and Education,
                under one roof, maintaining International standards at
                every stage of Lab and Lapidary process.
                Its lead faculty and technical staff is Internationally qualified,
                trained and experienced.

              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderBanner;
