 const host = 'https://db.gidindia.in';
//const host = 'http://localhost:4000';

export async function getApiResp(url) {
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Accept': '*/*',
            'User-Agent': 'Thunder Client (https://www.thunderclient.com)',
            'Content-Type': 'application/json'
        }
    });
    const json = await response.json()
    return json;
}
export async function getClarity() {    
    const url = `${host}/api/input/getClarity`
    getApiResp(url)
        .then((result) => {
            localStorage.setItem('clarity', JSON.stringify(result.data).replaceAll("Clarity", "BindField"));
        })
        .catch((error) => {
            return "Error in client apk";
        });
}
export async function getColor() {
    const url = `${host}/api/input/getColor`;

    getApiResp(url)
        .then((result) => {
            localStorage.setItem('color', JSON.stringify(result.data).replaceAll("color", "BindField",));
        })
        .catch((error) => {
            return "Error in client apk";
        });
}
export async function getComments() {
    const url = `${host}/api/input/getComments`
    getApiResp(url)
        .then((result) => {
            localStorage.setItem('comments', JSON.stringify(result.data).replaceAll("Comments", "BindField"));
        })
        .catch((error) => {
            return "Error in client apk";
        });
}
export async function getCut() {
    const url = `${host}/api/input/getCut`
    getApiResp(url)
        .then((result) => {
            localStorage.setItem('cut', JSON.stringify(result.data).replaceAll("Cut", "BindField"));
        })
        .catch((error) => {
            return "Error in client apk";
        });
}
export async function getDescription() {
    const url = `${host}/api/input/getdescription`
    getApiResp(url)
        .then((result) => {
            localStorage.setItem('description', JSON.stringify(result.data).replaceAll("Description", "BindField"));
        })
        .catch((error) => {
            return "Error in client apk";
        });
}
export async function getEstimatedWeight() {
    const url = `${host}/api/input/getEstimatedWeight`
    getApiResp(url)
        .then((result) => {
            localStorage.setItem('estimatedWeight', JSON.stringify(result.data).replaceAll("EstimatedWeight", "BindField"));
        })
        .catch((error) => {
            return "Error in client apk";
        });
}
export async function getExtraComments() {
    const url = `${host}/api/input/getExtraComments`
    getApiResp(url)
        .then((result) => {
            localStorage.setItem('extraComments', JSON.stringify(result.data).replaceAll("Extracomment", "BindField"));
        })
        .catch((error) => {
            return "Error in client apk";
        });
}
export async function getFinish() {
    const url = `${host}/api/input/getFinish`
    getApiResp(url)
        .then((result) => {
            localStorage.setItem('finish', JSON.stringify(result.data).replaceAll("Finish", "BindField"));
        })
        .catch((error) => {
            return "Error in client apk";
        });
}
export async function getGirdle() {
    const url = `${host}/api/input/getGirdle`
    getApiResp(url)
        .then((result) => {
            localStorage.setItem('girdle', JSON.stringify(result.data).replaceAll("Girdle", "BindField"));
        })
        .catch((error) => {
            return "Error in client apk";
        });
}
export async function getLuster() {
    const url = `${host}/api/input/getLuster`
    getApiResp(url)
        .then((result) => {
            localStorage.setItem('luster', JSON.stringify(result.data).replaceAll("Luster", "BindField"));
        })
        .catch((error) => {
            return "Error in client apk";
        });
}
export async function getMaking() {
    const url = `${host}/api/input/getMaking`
    getApiResp(url)
        .then((result) => {
            localStorage.setItem('making', JSON.stringify(result.data).replaceAll("Making", "BindField"));
        })
        .catch((error) => {
            return "Error in client apk";
        });
}
export async function getMaximumApproxYield() {
    const url = `${host}/api/input/getMaximumApproxYield`
    getApiResp(url)
        .then((result) => {
            localStorage.setItem('maximumApproxYield', JSON.stringify(result.data).replaceAll("MaximumApproxYield", "BindField"));
        })
        .catch((error) => {
            return "Error in client apk";
        });
}
export async function getMountedDiamondWeight() {
    const url = `${host}/api/input/getMountedDiamondWeight`
    getApiResp(url)
        .then((result) => {
            localStorage.setItem('mountedDiamondWeight', JSON.stringify(result.data).replaceAll("MountedDiamondWeight", "BindField"));
        })
        .catch((error) => {
            return "Error in client apk";
        });
}
export async function getOrient() {
    const url = `${host}/api/input/getOrient`
    getApiResp(url)
        .then((result) => {
            localStorage.setItem('orient', JSON.stringify(result.data).replaceAll("Orient", "BindField"));
        })
        .catch((error) => {
            return "Error in client apk";
        });
}
export async function getPolish() {
    const url = `${host}/api/input/getPolish`
    getApiResp(url)
        .then((result) => {
            localStorage.setItem('polish', JSON.stringify(result.data).replaceAll("Polish", "BindField"));
        })
        .catch((error) => {
            return "Error in client apk";
        });
}
export async function getRom() {
    const url = `${host}/api/input/getRom`
    getApiResp(url)
        .then((result) => {
            localStorage.setItem('rom', JSON.stringify(result.data).replaceAll("Rom", "BindField"));
        })
        .catch((error) => {
            return "Error in client apk";
        });
}
export async function getShape() {
    const url = `${host}/api/input/getShape`
    getApiResp(url)
        .then((result) => {
            localStorage.setItem('shape', JSON.stringify(result.data).replaceAll("Shape", "BindField"));
        })
        .catch((error) => {
            return "Error in client apk";
        });
}
export async function getShapesAndCuts() {
    const url = `${host}/api/input/getShapesAndCuts`
    getApiResp(url)
        .then((result) => {
            localStorage.setItem('shapeAndCut', JSON.stringify(result.data).replaceAll("ShapesAndCuts", "BindField"));
        })
        .catch((error) => {
            return "Error in client apk";
        });
}
export async function getSymmetry() {
    const url = `${host}/api/input/getSymmetry`
    getApiResp(url)
        .then((result) => {
            localStorage.setItem('symmetry', JSON.stringify(result.data).replaceAll("Symmetry", "BindField"));
        })
        .catch((error) => {
            return "Error in client apk";
        });
}
export async function getTransparency() {
    const url = `${host}/api/input/getTransparency`
    getApiResp(url)
        .then((result) => {
            localStorage.setItem('transparency', JSON.stringify(result.data).replaceAll("Transparency", "BindField"));
        })
        .catch((error) => {
            return "Error in client apk";
        });
}
export async function getUVFluorescence() {
    const url = `${host}/api/input/getUVFluorescence`
    getApiResp(url)
        .then((result) => {
            localStorage.setItem('uvFluorescence', JSON.stringify(result.data).replaceAll("UVFluorescence", "BindField"));
        })
        .catch((error) => {
            return "Error in client apk";
        });
}