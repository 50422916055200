import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import Aboutus from "../../../assets/Images/About/Aboutus.png";

const Founder = () => {
  return (
    <>
      <div className="container" id="Founder">
        <div className="about headding " data-aos="fade-right">
        <div><br/><br/><br/>
            <h1 className="about-title" style={{textAlign:"left"}}>About Chairman</h1>
            <p className="about-subtitle" style={{ textAlign: "justify" }}>
              Mr. Imran Shareef, is a graduate from GIA, New York, Sorting of Rough diamonds HRD Belgium and Chairman Diamond grading association T.S/A.P. His expertise lies in training students to develop skills like, Diamond cutting, Polishing, Grading, testing and appraisal.<br/><br/>
              <ul>
                <li>Certified Diamond Grader &#40;GIA, New York&#41;.</li>
                <li>Proud Alumni Member, GIA, USA.</li>
                <li>Sorting of Rough Diamond HRD Antwerp, Belgium.</li>
                <li>Gem Grader, GIA, USA.</li>
                <li>Pearl Grader, GIA, USA.</li>
                <li>Gold Appraiser, Govt. Of India.</li>
                <li>CMD: Golconda Institute of Diamonds. </li>
                <li>Chairman: Diamond Grading Association</li>
                <li>Lead Faculty: Govt. Of India.</li>
                <li>Ministry of Micro Small &#38; Medium Enterprises.</li>
                <li>Proprietor: I.S. Jewel's.</li>
              </ul><br/>A close observer of the market of precious stones and diamonds, Imran felt there was a need for a Gem Stone testing unit in Hyderabad. Here the services of Gem grading, sourcing and cutting would be available for retail shops as well as customers who want to develop a proficiency in the subject. This led to the establishment of GID in 2007 - Imran Shareef is FOUNDER of GID &#40;Golconda Institute of Diamonds&#41; Hyderabad, which is associated with MSME &#40;Ministry Of Small and Medium Enterprises&#41; and with GSI &#40;Geological Survey of India&#41; for training geologists about Diamond Grading. He has now successfully trained over 2000 people in India and abroad. GID's Gem certification are recognised by 56 Countries across the Globe including U.S.A, U.K &#38; Australia. MR. IMRAN SHAREEF a life time proud ALUMINI and Graduate Diamond Grader of GIA, New York, USA, is an only Grader for rough as well as polished Diamonds in T.S. and A.P. trained from GIA - USA and HRD-BELGIUM. He was invited by MSME Govt. of India to traine jewellers as well as interested persons in Diamond Grading. So far thousands of people has been trained by him in India. He is Diamond Consultant to various companies in India and abroad. Certified Diamond Grader &#40; GIA, New York, USA&#41; Sorting of Rough Diamonds &#40;HRD - Antwerp Belgium &#41; Gold Appraisal : &#40;Govt. Of India MSME&#41; Lead Faculty : &#40; Govt. Of India MSME&#41; Founder :GID Institute of Diamonds Chairman : Diamond Grading Association, A.P. Diamond Consultant :Sha Jewellers &#40; Chicago &#41; U.S.A : New Deccan jewellers &#40;HYD &#41; India.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Founder;
