import React from "react";
import Footer from "./Component/Footer";
import Header from "./Component/Header";
import Cursor from "./Cursor";
import HeaderBanner from "./Component/Components/HeaderBanner";
import Aboutus from "./Component/Components/About";
import Founder from "./Component/Components/Founder";
import Consumer from "./Component/Components/Consumer";
import Experience from "./Component/Components/Experience";
import MyPortfolio from "./Component/Components/MyPortfolio";
import ScrollButton from "./Component/ScrollButton";
import Brands from "./Component/Components/Brands";
import "./indexHome.css";
const Index = () => {
  return (
    <div className="App">
      <Header />
      <Cursor />
      <HeaderBanner />
      <Aboutus />
      <Founder />
      <Experience />      
      <Consumer />
      <Brands/>
      <MyPortfolio/>
      <Footer />
      <ScrollButton />
    </div>
  );
};

export default Index;
