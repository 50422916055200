import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import Aboutus from "../../../assets/Images/About/Aboutus.png";

const About = () => {
  return (
    <>
      <div className="container" id="About">
        <div className="about headding " data-aos="fade-right">
          <Row className="about-main">
            <Col md={5}>
              <div className="about-img ">
                <Image
                  data-aos="flip-right"
                  data-aos-duration="1200"
                  data-aos-delay="100"
                  className="d-block img-fluid mx-auto rounded-circle"
                  src={Aboutus}
                />
              </div>
            </Col>
            <Col md={7} className="abouttextbox">
              <div><br /><br /><br />
                <h1 className="about-title">About Us</h1>
                <p className="about-subtitle" style={{ textAlign: "justify" }}>

                  Golconda Institute of Diamonds is the only place where you can enhance you skills and learn about diamond grading, jewellery trade, gem identification, gold appraisal and jewellry designing. GID is a approved by Ministry of Micro Small and Medium Enterprise &#40;MSME&#41;, a member of Gems and jewelry Export Promotion council &#40;GJEPC&#41; and SETWIN &#40;A Society of Telangana &#38; A.P. Government&#41;, <br /><br />

                  1. GID Testing Lab is well equipped with latest testing equipments, using the correct magnification, and dark field illumination, for testing the polished DIAMONDS, GEMS, &#40; colour stones&#41; ROUGH &#38; PEARLS. Every step of testing is done with atmost care in as little as possible time.<br /><br />

                  2. DIAMONDS / GEMS certified by GID will enhance your confidence to sell your end products.<br /><br />

                  3 THE characteristics of the tested DIAMONDS / GEMS / PEARLS &#38; MOUNTED JEWELLERY shall be established by scientific measurements and observations.<br /><br />

                  4. GID Grades DIAMONDS, using GIA'S world famous grading system.<br /><br />

                  5. Your DIAMONDS / GEMS certified from GID shall help you to buy, or sell, will definitely make the difference between a poor and profitable business.<br /><br />
                </p>
              </div>
            </Col>
          </Row><br />

        </div>
      </div>
    </>
  );
};

export default About;
