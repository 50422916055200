
// const host = "http://localhost:4000"
const host = 'https://db.gidindia.in';

const headers = { 'Content-Type': 'application/json' }
export async function getApiResp(url) {
    const response = await fetch(url, {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    });
    const json = await response.json()
    return json;
}

export async function getCertificateDG() {
    const url = `${host}/api/certificate/getCertDG`
    const result = getApiResp(url)
        .then((result) => {
            const resultList = result.data;
            let list = [];
            var num = 0;
            resultList.forEach(column => {
                let row = {};
                row.SrNo = num += 1;
                row.CertNo = column.CertificateNumber;
                row.Clarity = column.Clarity;
                row.Symmetry = column.Symmetry;
                row.Color = column.Color;
                row.Girdle = column.Girdle;
                row.CaratWeight = column.CaratWeight;
                row.Polish = column.Polish;
                row.ShapeAndCut = column.ShapeAndCut;
                row.Comments = column.Comments;
                row.UVFluorescence = column.UVFluorescence;
                row.Desc = column.Description;
                row.CustomerName = column.CustomerName;
                row.SelectImage = column.SelectImage;
                // row.Contact = column.Contact;
                // row.SelectDate =new Date(column.SelectDate).getDate();                
                // row.CertificateIssuedDate = column.Date;
                list.push(row)
            })
            return JSON.stringify(list);
        })
        .catch((error) => {
            return "Error in client apk - DG";
        });
    return result;
}
export async function getCertificateGEM() {
    const url = `${host}/api/certificate/getCertGem`
    const result = getApiResp(url)
        .then((result) => {
            const resultList = result.data;
            let list = [];
            var num = 0;
            resultList.forEach(column => {
                let row = {};
                row.SrNo = num += 1;
                row.CertNo = column.CertificateNumber;
                row.Color = column.Color;
                row.Transparency = column.Transparency;
                row.Shape = column.Shape;
                row.Cut = column.Cut;
                row.Comments = column.Comments;
                row.UVFluorescence = column.UVFluorescence;
                row.Desc = column.Description;
                row.CustomerName = column.CustomerName;
                row.SelectImage = column.SelectImage;
                // row.Contact = column.Contact;
                // row.SelectDate =new Date(column.SelectDate).getDate();                
                // row.CertificateIssuedDate = column.Date;
                list.push(row)
            })
            return JSON.stringify(list);
        })
        .catch((error) => {
            return "Error in client apk - GEM";
        });
    return result;
}
export async function getCertificateMJ() {
    const url = `${host}/api/certificate/getCertMJ`
    const result = getApiResp(url)
        .then((result) => {
            const resultList = result.data;
            let list = [];
            var num = 0;
            resultList.forEach(column => {
                let row = {};
                row.SrNo = num += 1;
                row.CertNo = column.CertificateNumber;
                // row.Clarity = column.Clarity;
                // row.Symmetry = column.Symmetry;
                row.Color = column.Color;
                // row.Girdle = column.Girdle;
                row.CaratWeight = column.CaratWeight;
                row.Rom = column.Rom;
                row.MaxYield = column.MaximumYield;
                row.Comments = column.Comments;
                row.UVFluorescence = column.UVFluorescence;
                row.Desc = column.Description;
                row.CustomerName = column.CustomerName;
                row.SelectImage = column.SelectImage;
                // row.Contact = column.Contact;
                // row.SelectDate =new Date(column.SelectDate).getDate();                
                // row.CertificateIssuedDate = column.Date;
                list.push(row)
            })
            return JSON.stringify(list);
        })
        .catch((error) => {
            return "Error in client apk - MJ";
        });
    return result;
}
export async function getCertificateMJG() {
    const url = `${host}/api/certificate/getCertMJG`
    const result = getApiResp(url)
        .then((result) => {
            const resultList = result.data;
            let list = [];
            var num = 0;
            resultList.forEach(column => {
                let row = {};
                row.SrNo = num += 1;
                row.CertNo = column.CertificateNumber;
                row.Clarity = column.Clarity;
                row.EstmWeight = column.EstimatedWeight;
                row.OSWeight = column.OtherStoneWeight;
                row.MDWeight = column.MountedDiamondWeight;
                row.Comments = column.ExtraComments;
                row.ShapeCut = column.ShapeAndCut;
                row.Desc = column.Description;
                row.CustomerName = column.CustomerName;
                row.SelectImage = column.SelectImage;
                // row.Contact = column.Contact;
                // row.SelectDate =new Date(column.SelectDate).getDate();                
                // row.CertificateIssuedDate = column.Date;
                list.push(row)
            })
            return JSON.stringify(list);
        })
        .catch((error) => {
            return "Error in client apk - MJG";
        });
    return result;
}
export async function getCertificatePG() {
    const url = `${host}/api/certificate/getCertPG`
    const result = getApiResp(url)
        .then((result) => {
            const resultList = result.data;
            let list = [];
            var num = 0;
            resultList.forEach(column => {
                let row = {};
                row.SrNo = num += 1;
                row.CertNo = column.CertificateNumber;
                row.Grams = column.Grams;
                row.Lustre = column.Lustre;
                row.Color = column.Color;
                row.Making = column.Making;
                row.Shape = column.Shape;
                row.Size = column.Size;
                row.ShapeAndCut = column.ShapeAndCut;
                row.Comments = column.Comments;
                row.UVFluorescence = column.UVFluorescence;
                row.Orient = column.Orient;
                row.Desc = column.Description;
                row.CustomerName = column.CustomerName;
                // row.Contact = column.Contact;
                // row.SelectDate =new Date(column.SelectDate).getDate();                
                // row.CertificateIssuedDate = column.Date;
                list.push(row)
            })
            return JSON.stringify(list);
        })
        .catch((error) => {
            return "Error in client apk - PG";
        });
    return result;
}
export async function getCertificateRD() {
    const url = `${host}/api/certificate/getCertRD`
    const result = getApiResp(url)
        .then((result) => {
            const resultList = result.data;
            let list = [];
            var num = 0;
            resultList.forEach(column => {
                let row = {};
                row.SrNo = num += 1;
                row.CertNo = column.CertificateNumber;
                row.Clarity = column.Clarity;
                row.ShapeAndCut = column.ShapeAndCut;
                row.Color = column.Color;
                row.EstmWeight = column.EstimatedWeight;
                row.OSWeight = column.OtherStoneWeight;
                row.MountedGemWeight = column.MountedGemWeight;
                row.Transparency = column.Transparency;
                row.Comments = column.ExtraComments;
                row.Fluorescence = column.Fluorescence;
                row.Desc = column.Description;
                row.CustomerName = column.CustomerName;
                row.SelectImage = column.SelectImage;
                // row.Contact = column.Contact;
                // row.SelectDate =new Date(column.SelectDate).getDate();                
                // row.CertificateIssuedDate = column.Date;
                list.push(row)
            })
            return JSON.stringify(list);
        })
        .catch((error) => {
            return "Error in client apk - RD";
        });
    return result;
}