import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'

const Experience = () => {
    return (
        <>
            <div id="Experience" className="exp-main headding"><br/><br/><br/>
                <div className="exp ">
                    <h1 className="text-center title">Courses</h1>
                </div>
                <Container >
                    <Row >
                        <Col lg={6} md={6} sm={6}>
                            <div className="exp-card" data-aos="flip-left">
                                <Card className="exp-block">
                                    <Card.Body className="exp-body">
                                        <Card.Title className="exp-title">Diamond Grading
                                            <p>
                                                Monday to Friday | 2 hours
                                            </p>
                                        </Card.Title>
                                        <hr />
                                        <Card.Text className="exp-text">
                                            DURATION 8 WEEKS<br />
                                            Rs. : 35,000/-&#40;Inclusive of all taxes&#41; &#38;<br />
                                            &#40;+&#41; Rs. 3,000/- Refundable Deposit<br />
                                            Monday to Friday<br />
                                            Class Timings: daily 2 hours<br /><br />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={6}>
                            <div className="exp-card" data-aos="flip-left">
                                <Card className="exp-block">
                                    <Card.Body className="exp-body">
                                        <Card.Title className="exp-title">Diamond Cutting &#38; Polish
                                            <p>
                                                Monday to Friday | 1 hours
                                            </p>
                                        </Card.Title>
                                        <hr />
                                        <Card.Text className="exp-text">
                                            DURATION 6 WEEKS<br />
                                            Rs. 15,000/- &#40;Inclusive of all taxes&#41;<br />
                                            &#40;+&#41; Rs. 5,000/- Deposit<br />
                                            Monday to Friday<br />
                                            1 Week Theory 5 Weeks Practical<br />
                                            Timings : 1 hour daily<br />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={6}>
                            <div className="exp-card" data-aos="flip-left">
                                <Card className="exp-block">
                                    <Card.Body className="exp-body">
                                        <Card.Title className="exp-title">Gem Identification
                                            <p>
                                                Monday to Friday | 2 hours
                                            </p>
                                        </Card.Title>
                                        <hr />
                                        <Card.Text className="exp-text">
                                            DURATION 8 WEEKS<br />
                                            Rs: 35,000/ &#40;Inclusive of all taxes&#41;<br />
                                            Monday to Friday<br />
                                            Timings : 2 hours daily<br />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={6}    >
                            <div className="exp-card" data-aos="flip-left">
                                <Card className="exp-block">
                                    <Card.Body className="exp-body">
                                        <Card.Title className="exp-title">Jewellry Design
                                            <p>
                                                Monday to Friday | 3 days in a week
                                            </p>
                                        </Card.Title>
                                        <hr />
                                        <Card.Text className="exp-text">
                                            DURATION 8 WEEKS<br />
                                            Rs: 35,000/ &#40;Inclusive of all taxes&#41;<br />
                                            3 day in a week<br /><br />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={6}    >
                            <div className="exp-card" data-aos="flip-left">
                                <Card className="exp-block">
                                    <Card.Body className="exp-body">
                                        <Card.Title className="exp-title">Gold Appraisal
                                            <p>
                                                Monday to Friday | 2 hours
                                            </p>
                                        </Card.Title>
                                        <hr />
                                        <Card.Text className="exp-text">
                                            DURATION 4 WEEKS<br />
                                            Rs: 15,000/ &#40;Inclusive of all taxes&#41;<br />
                                            Rs: 2000/- Deposit<br />
                                            Monday to Friday<br />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={6}    >
                            <div className="exp-card" data-aos="flip-left">
                                <Card className="exp-block">
                                    <Card.Body className="exp-body">
                                        <Card.Title className="exp-title">Retail Jewelry Sales
                                            <p>
                                                Monday to Friday | 2 hours
                                            </p>
                                        </Card.Title>
                                        <hr />
                                        <Card.Text className="exp-text">
                                            DURATION 2 WEEKS<br />
                                            Rs:6,000/ &#40;Inclusive of all taxes&#41;<br />
                                            Monday to Friday<br /><br />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Experience