import { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BsTelephone } from "react-icons/bs";
import { BsEnvelope } from "react-icons/bs";
import { BiMap } from "react-icons/bi";

export default class Footer extends Component {
  // const sendEmail = (e) => {
  //   e.preventDefault();
  //   // https://www.emailjs.com/docs/examples/reactjs/
  //   emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_PUBLIC_KEY')
  //     .then((result) => {
  //       console.log(result.text);
  //     }, (error) => {
  //       console.log(error.text);
  //     });
  // };
  render() {
    return (
      <>
        <footer className="footer headding" id="Contact">
          <div className="footer-head">
            <Container>
              <div className="py-5 text-center">
                <iframe
                  src='https://maps.google.com/maps?q=golconda%20instititue%20of%20diamond&t=&z=17&ie=UTF8&iwloc=&output=embed'
                  width="100% auto"
                  height="350px"
                  frameBorder="0"
                  title="Iframe"
                  aria-hidden="false"
                  tabIndex="0"
                />
              </div>

              <Row>
                <Col lg={6} md={12}>
                  <div className="f-left">
                    <div className="f-content">
                      <h1 className="F-contact">Contact Us</h1>
                      <h1 className="f-text">Let's Serve You Better.</h1>
                    </div>
                    <div className="">
                      <h6 className="f-icon">
                        <i className="icon">
                          <BsTelephone size={20} />
                        </i>
                        <span>+91-4023351687, +91-9989877768</span>
                      </h6>
                      <h6 className="f-icon">
                        <i className="icon">
                          <BsEnvelope size={20} />
                        </i>
                        <span>contactus@gidindia.in</span>
                      </h6>
                      <h6 className="f-icon">
                        <i className="icon">
                          <BiMap size={20} />
                        </i>
                        <span>Door No 8-2-577/B, Ground Floor, <br />Maas Heights, Road No 8, Banjara Hills, <br />Hyderabad - 500034, Opposite Irani Counselets.</span>
                      </h6>
                    </div>
                  </div>
                </Col>
                <Col lg={6} md={12} className="f-end">

                  <Row>
                    <Col xl={12} md={12} lg={12} sm={12}>
                      <div>

                        <form className="row g-3 needs-validation formContactus">
                          <div className="col-md-6  col-sm-6 col-6">
                            <input
                              type="text"
                              className="form-control inputbox "
                              id="validationCustom01"
                              placeholder="Name"
                              name="user_name"
                              required
                            />
                          </div>
                          <div className="col-md-6  col-sm-6 col-6">
                            <input
                              type="text"
                              className="form-control inputbox"
                              id="validationCustom01"
                              placeholder="Phone"
                              required
                            />
                          </div>
                          <div className="col-md-6 col-sm-6 col-6">
                            <input
                              type="text"
                              className="form-control inputbox"
                              id="validationCustom02"
                              placeholder="E-mail"
                              name="user_email"
                              required
                            />
                          </div>
                          <div className="col-md-6 col-sm-6 col-6">
                            <input
                              type="text"
                              className="form-control inputbox "
                              id="validationCustom01"
                              placeholder="Subject"
                              required
                            />
                          </div>

                          <div className="col-md-12">
                            <textarea
                              type="text"
                              className="form-control inputboxmessage inputbox"
                              id="validationCustom03"
                              placeholder="Message*"
                              name="message"
                              required
                            />
                            <div className="invalid-feedback">
                              Please provide a Send Message.
                            </div>
                          </div>

                          <div className="col-12 ">
                            <button type="submit" value="Send" className="send-btnGreen button float-start">Send Message</button>
                          </div>
                        </form>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </footer>
        <div className="f-link">Copyright © 2021. All Rights Reserved.</div>
      </>
    );
  }
}
