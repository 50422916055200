import React, { useState } from 'react'
import * as GetApi from '../ApiCall/GetCertificatesList';
import Loader from '../Utility/Loader'

export default function CertList() {
  //var TableData = [{}];
  const myFunction = (e) => {
    // Declare variables
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("myInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("myTable");
    tr = table.getElementsByTagName("tr");

    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[1];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }
  var [TableData, setTableData] = useState([{}]);
  const [isLoader, setLoader] = useState(false);
  var column = Object.keys(TableData[0]);
  var ThData = () => {

    return column.map((data) => {
      return <th key={data}>{data.replace(/([A-Z])/g, ' $1').trim()}</th>
    })
  }

  var tdData = () => {

    return TableData.map((data) => {
      return (
        <tr>
          {
            column.map((v) => {
              let val = '' + data['SelectImage'];
              if (v === 'SelectImage') {
                if (val.includes('.png') || val.includes('.jpg')) {
                  return (
                    <td>
                      <img src={`https://db.gidindia.in/certificateImage/${data[v]}`} width="40px" height="40px" alt='no preview' />
                      {/* <img src={`http://localhost:4000/certificateImage/${data[v]}`} alt='no preview'
                        width="40px" height="40px" /> */}
                    </td>
                  )
                }
              }
              else {
                return (
                  <td>{data[v]}</td>
                )
              }
            })
          }
        </tr>
      )
    })
  }
  const onChangeList = (e) => {
    e.preventDefault();
    var selectedCert = parseInt([e.target.value])
    var result = null;
    switch (selectedCert) {
      case 1:
        setLoader(true)
        result = GetApi.getCertificateDG();
        result.then((list) => { if (list.length > 2) { setTableData(JSON.parse(list)); } setLoader(false) })
        break;
      case 2:
        setLoader(true)
        result = GetApi.getCertificateGEM();
        result.then((list) => { if (list.length > 2) { setTableData(JSON.parse(list)); } setLoader(false) })
        break;
      case 3:
        setLoader(true)
        result = GetApi.getCertificateMJ();
        result.then((list) => { if (list.length > 2) { setTableData(JSON.parse(list)); } setLoader(false) })
        break;
      case 4:
        setLoader(true)
        var result = GetApi.getCertificateMJG();
        result.then((list) => { if (list.length > 2) { setTableData(JSON.parse(list)); } setLoader(false) })
        break;
      case 5:
        setLoader(true)
        result = GetApi.getCertificatePG();
        result.then((list) => { if (list.length > 2) { setTableData(JSON.parse(list)); } setLoader(false) })
        break;
      case 6:
        setLoader(true)
        result = GetApi.getCertificateRD();
        result.then((list) => { if (list.length > 2) { setTableData(JSON.parse(list)); } setLoader(false) })
      default:
        break;
    }
  }
  return (
    <>
      <div className="row p-1">
        <div className="text-muted fw-bold text-uppercase mt-2 text-right">
          Certificates:<select id="certType" className='m-1' onChange={onChangeList}>
            <option selected disabled hidden>--Select Certificate--</option>
            <option value="1">Diamond Grading</option>
            <option value="2">Gem</option>
            <option value="3">Mounted Jewelry</option>
            <option value="4">Mounted Jewelry Gem</option>
            <option value="5">Pearl Grading</option>
            <option value="6">Rough Diamond</option>
          </select>
          <input className="text-muted" type="text" id="myInput" onKeyUp={myFunction} placeholder="Search Cert no.." style={{ marginLeft: '5px', padding: 3, border: 'none' }} />
          <div style={{ float: 'right', height: '3px' }}>{isLoader && <Loader />}</div>
        </div>
      </div>
      <div className='container-fluid' style={{ overflowX: 'auto', whiteSpace: 'nowrap', backgroundColor: 'white', fontSize: 'small', height: '450px' }}>

        <table id='myTable' className='table table-hover tableGrid'>
          <thead>
            <tr style={{ position: 'sticky', top: 0, backgroundColor: '#fff', color: 'gray' }}>{ThData()}</tr>
          </thead>
          <tbody>
            {tdData()}
          </tbody>
        </table>
      </div>
    </>
  )
}
