import { Bars   } from 'react-loader-spinner'

export default function Loader() {
  return (
    <div>
      <Bars  
        color='grey'
        width={40}
        height={40}
        ariaLabel='loading'
      />
    </div >
  )
}