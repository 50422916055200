import img1 from '../../../assets/Images/Portfolio/img1.png'
import img2 from '../../../assets/Images/Portfolio/img2.png'
import img3 from '../../../assets/Images/Portfolio/img3.png'
import img4 from '../../../assets/Images/Portfolio/img4.png'
import img5 from '../../../assets/Images/Portfolio/img5.png'
import img6 from '../../../assets/Images/Portfolio/img6.png'

export const PortfolioImg = [
    {
        id: 1,
        image: img1,
        category: "fashion"
        
    },
    {
        id: 2,
        image: img2,
        category: "fashion"
       
    },
    {
        id: 3,
        image: img5,
        category: "photography"
        
    },
    {
        id: 4,
        image: img4,
        category: "product"
        
    },
    {
        id: 5,
        image: img3,
        category: "Branding"
       
    },
    {
        id: 6,
        image: img6,
        category: "product"
        
    },
]