import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// const host = "http://localhost:5000"
 const host = 'https://gidindia.herokuapp.com';
// const host = 'http://gidserver.localhost';

const Login = (props) => {
    const [credentials, setCredentials] = useState({ userName: "", password: "" })
    let history = useHistory();
    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch(`${host}/api/User/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userName: credentials.userName, password: credentials.password })
        });
        const json = await response.json()
        if (json.authtoken) {
            // Save the auth token and redirect
            localStorage.setItem('token', json.authtoken);
            localStorage.setItem('userGid', credentials.userName);
            history.push("/dashboard");

        }
        else {
            toast("Invalid credentials.", {
                autoClose: 1000
            });
        }
    }

    const onChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value })
    }

    return (
        <form onSubmit={handleSubmit}>
            <section className="gradient-custom">
                <div className="container">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                            <div className="card bg-dark text-white" style={{ borderRadius: '1rem' }}>
                                <div className="card-body p-2 text-center">
                                    <div className="mb-md-0 mt-md-0 pb-0">
                                        <h2 className="fw-bold mb-0 text-uppercase" style={{ fontSize: 24 }}>Login</h2>
                                        <p className="text-white-50 mb-5">Please enter your login and password!</p>
                                        <div className="form-outline form-white">
                                            <input type="text" id="typeEmailX" className="form-control form-control-lg" value={credentials.userName} onChange={onChange} id="userName" name="userName" />
                                            <label className="form-label" htmlFor="typeEmailX">Email</label>
                                        </div>
                                        <div className="form-outline form-white mb-4">
                                            <input type="password" id="typePasswordX" className="form-control form-control-lg" value={credentials.password} onChange={onChange} name="password" id="password" />
                                            <label className="form-label" htmlFor="typePasswordX">Password</label>
                                        </div>
                                        {/* <p className="small mb-5 pb-lg-2"><a className="text-white-50" href="#!">Forgot password?</a></p> */}
                                        <button className="btn btn-outline-light btn-lg px-5" type="submit">Login</button>
                                        <div className="d-flex justify-content-center text-center mt-4 pt-1">
                                            <a href="#!" className="text-white"><i className="fab fa-facebook-f fa-lg" /></a>
                                            <a href="#!" className="text-white"><i className="fab fa-twitter fa-lg mx-4 px-2" /></a>
                                            <a href="#!" className="text-white"><i className="fab fa-google fa-lg" /></a>
                                        </div>
                                    </div>
                                    <ToastContainer />
                                    {/* <div>
                                            <p className="mb-0">Don't have an account? <a href="#!" className="text-white-50 fw-bold">Sign Up</a>
                                            </p>
                                        </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </form>
    )
}

export default Login