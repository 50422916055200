import React from 'react'
import { useHistory } from 'react-router-dom'

export default function AppHeader() {
  let history = useHistory();
  const onLogoutClick = (e) => {
    e.preventDefault();
    localStorage.removeItem('userGid')
    history.push("/Login");
  }
  return (
    <nav className="navbar navbar-expand-lg navbar-dark theme-custom-gray fixed-top" style={{padding:10}}>
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#sidebar"
          aria-controls="offcanvasExample"
        >
          <span class="navbar-toggler-icon" data-bs-target="#sidebar"></span>
        </button>
        <a
          className="navbar-brand me-auto ms-lg-0 ms-3 text-uppercase"
          href="#"
        >Golconda Institute Of Diamond</a
        >
        <div className="collapse navbar-collapse" id="`topNavBar`">
          <form className="d-flex ms-auto my-3 my-lg-0">
            <div className="input-group">

            </div>
          </form>
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle ms-2"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="bi bi-person-fill"></i>
              </a>
              <ul className="dropdown-menu dropdown-menu-end">
                <li><a className="dropdown-item" href="#">Login as: {localStorage.getItem('userGid')}</a></li>
                <li>
                  <a className="dropdown-item" onClick={onLogoutClick} href="#">Logout</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div >
    </nav >
  );
}
