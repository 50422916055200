export const genCertNo = () => {
    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let hours = newDate.getHours().toString() + newDate.getMinutes().toString() + newDate.getSeconds().toString();
    const certNo = `${date}${month < 10 ? `0${month}` : `${month}`}${year}${hours}`
    return certNo;
}

//dd MM yyyy hh mm ss
//31 12 2022 12 33 12