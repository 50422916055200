import { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BsFacebook, BsInstagram, BsLinkedin, BsTelephone, BsTwitter } from "react-icons/bs";
import { BsEnvelope } from "react-icons/bs";
import { BiMap } from "react-icons/bi";

export default class SocialMedia extends Component {
    render() {
        return (
            <>
                <div style={{ marginBottom: "14px", marginLeft: "21px" }} id="SocialMedia">
                    <a href="https://www.facebook.com/profile" target="_blank">
                        <BsFacebook style={{ marginRight: "4px" }} size={20} />
                    </a>  
                    <a href="https://instagram.com/" target="_blank">
                        <BsInstagram style={{ marginRight: "4px" }} size={20} />
                    </a>
                    <a href="https://twitter.com/" target="_blank">
                        <BsTwitter style={{ marginRight: "4px" }} size={20} />
                    </a>
                    <a href="https://linkedin.com/" target="_blank">
                        <BsLinkedin style={{ marginRight: "4px" }} size={20} />
                    </a>

                </div>
            </>
        );
    }
}
