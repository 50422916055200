import React, { useRef } from 'react';
import { useReactToPrint } from "react-to-print";

export default function DiamondGradingPrint(props) {
    return (
        <>
            <div id="divToPrint" style={{ display: 'none' }}>
                <div style={{ marginTop: '43px' }}>
                    <h6 style={{ marginLeft: 168, fontWeight: 'bold', fontSize: '12px', width: '270px', marginBottom: '10px' }}>
                        <u>DIAMOND GRADING CERTIFICATE</u>
                    </h6>
                </div>
                <div style={{ float: 'left', fontSize: '10px', marginLeft: '25px', width: '280px' }}>
                    <table className='tb'>
                        <tr>
                            <td>Certificate No.</td>
                            <td>:</td>
                            <td width={200}>{props.CertificateNumber}</td>
                        </tr>
                        <tr>
                            <td>Description</td>
                            <td>:</td>
                            <td>{props.Description} </td>
                        </tr>
                        <tr>
                            <td>Clarity</td>
                            <td>:</td>
                            <td>{props.Clarity}</td>
                        </tr>
                        <tr>
                            <td>Color</td>
                            <td>:</td>
                            <td>{props.Color}</td>
                        </tr>
                        <tr>
                            <td>Shape &#38; Cut</td>
                            <td>:</td>
                            <td>{props.ShapeAndCut}</td>
                        </tr>
                        <tr>
                            <td>Carat Weight</td>
                            <td>:</td>
                            <td>{props.CaratWeight}</td>
                        </tr>
                        <tr>
                            <td>Girdle</td>
                            <td>:</td>
                            <td>{props.Girdle}</td>
                        </tr>
                        <tr>
                            <td>Polish</td>
                            <td>:</td>
                            <td>{props.Polish}</td>
                        </tr>
                        <tr>
                            <td>Symmetry</td>
                            <td>:</td>
                            <td>{props.Symmetry}</td>
                        </tr>
                        <tr>
                            <td>UV Fluorescence</td>
                            <td>:</td>
                            <td>{props.UVFluorescence}</td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: 'top' }}>Comments</td>
                            <td>:</td>
                            <td>{props.Comments}</td>
                        </tr>
                    </table>
                </div>
                <div style={{ marginLeft: 5, fontSize: 12 }} >
                    <div style={{ float: 'left', marginLeft: '70px', marginTop: '35px' }}>
                        <img alt="..."style={{ width: 88, height: 88, border: '1px solid #555', borderRadius: 10 }} src={props.imagePreview}></img>
                    </div>
                    <div style={{ textAlign: 'center', marginLeft: -90, marginTop: 150, fontSize: 10, float: 'left', fontWeight: 'bold' }}>
                        <span>IMRAN SHAREEF</span> <br />
                        <span>Gemologist</span> <br />
                        <span>GIA&#46;&nbsp;USA</span><br />
                        <sp>(D):&nbsp;{new Date(props.valueDate.selectedDate).getDate() + '-' +
                            new Date(props.valueDate.selectedDate).getMonth()
                            + '-' + new Date(props.valueDate.selectedDate).getFullYear()}</sp>
                    </div>
                </div>
            </div>
        </>
    )
}