import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import Index from './pages/Index';
import Header from "./components/AppModules/AppHeader";
import NavMenu from "./components/AppModules/AppSideMenu";
import Content from './components/AppModules/AppContent'
import { Route } from 'react-router-dom';
import Login from "./Login";

function App() {
  return (
    <>
      <Router>
        <Route path='/' exact component={Index} />
        <Route path='/login' component={Login} />
        <Route key="Content" path='/dashboard'>
          <Header />
          <NavMenu />
          <Content />
        </Route>
      </Router>
    </>
  );

}

export default App;
