import React from 'react'
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import * as GetApi from "../ApiCall/GetApi";

export default function AppDashboard() {
  // let history = useHistory();
  // if (!localStorage.getItem('userGid')) {
  //   alert("Your session is expired. Please login again.")
  //   history.push("/login");
  // }
  useEffect(() => {
    GetApi.getClarity();
    GetApi.getColor();
    GetApi.getComments();
    GetApi.getCut();
    GetApi.getDescription();
    GetApi.getEstimatedWeight();
    GetApi.getExtraComments();
    GetApi.getFinish();
    GetApi.getGirdle();
    GetApi.getLuster();
    GetApi.getMaking();
    GetApi.getMaximumApproxYield();
    GetApi.getMountedDiamondWeight();
    GetApi.getOrient();
    GetApi.getPolish();
    GetApi.getRom();
    GetApi.getShape();
    GetApi.getShapesAndCuts();
    GetApi.getSymmetry();
    GetApi.getTransparency();
    GetApi.getUVFluorescence();
  }, [])
  return (
    <>
      <div className="row p-1">
        <div className="text-muted fw-bold text-uppercase mt-2">
          Dashboard
        </div>
      </div>
      <hr className="dropdown-divider bg-dark mb-1" />
    </>
  )
}
