import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export default function AppSideMenu() {
  const menuItems = ["Diamond Grading", "Gem", "Rough Diamond", "Mounted Jewelry", "Pearl Grading", "Mounted Jewelry Gem"];
  const [selectedMI, setSelectedMI] = useState(menuItems[0]);
  return (

    <div className="offcanvas offcanvas-start sidebar-nav theme-custom-gray"
      tabindex="-1"
      id="sidebar">
      <div className="offcanvas-body p-0">
        <nav className="navbar-dark">
          <ul className="navbar-nav">
            <li className={selectedMI === "/dashboard" ? "activeMenu" : ""}>
              <Link key="dashboard" to='/dashboard' onClick={() => setSelectedMI("/dashboard")}
                className="nav-link px-3">
                <span className="me-2"><i className="bi bi-speedometer2" /></span>
                <span>Dashboard</span>
              </Link>
            </li>
            <li><hr className="dropdown-divider bg-light" /></li>
            <li>
              <div id='linkDashboard' className="text-muted small fw-bold text-uppercase px-3 mb-3">
                Certificates
              </div>
            </li>
            <li>
              <a className="nav-link px-3 sidebar-link" data-bs-toggle="collapse" href="#layouts">
                <span className="me-2"><i className="bi bi-layout-split" /></span>
                <span>Create New</span>
                <span className="ms-auto">
                  <span className="right-icon">
                    <i className="bi bi-chevron-down" />
                  </span>
                </span>
              </a>
              {/* className={"collapse"} */}
              <div className={selectedMI === "/dashboard" ? "collapse" : selectedMI === "/list" ? "collapse" : "collapse show"} id="layouts">
                <ul className="navbar-nav ps-3">
                  {menuItems.map((menuItem) => (
                    <li className={selectedMI === menuItem ? "activeMenu" : ""}>
                      <Link key={menuItem.toString()} to={"/dashboard/" + menuItem.replace(/ /g, '')}
                        onClick={() => setSelectedMI(menuItem)}
                        className="nav-link px-3">
                        <span className="me-2"><i className="bi bi-book-fill" /></span>
                        <span>{menuItem}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
            <li className={selectedMI === "/list" ? "activeMenu" : ""}>
              <Link onClick={() => setSelectedMI("/list")}
                to='/dashboard/List' className="nav-link px-3">
                <span className="me-2"><i className="bi bi-book-fill" /></span>
                <span>View List</span>
              </Link>
            </li>
            <li ><hr className="dropdown-divider bg-light" /></li>
          </ul>
        </nav>
      </div>
    </div>
  )
}