import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import Aboutus from "../../../assets/Images/About/Aboutus.png";

const Consumer = () => {
    return (
        <>
            <div className="container" id="Consumer">
                <div className="about headding " data-aos="fade-right">
                    <div>
                        <br /><br /><br /><h1 className="about-title" style={{ textAlign: "left" }}>Download your certificate.</h1>
                        <br /><br />
                        <div>
                            <div className="row g-3 needs-validation">
                                <div className="form-control">
                                    <input id="txtCertificateNo" style={{ width: '25%' }}
                                        type="text"
                                        maxLength='15'
                                        className="form-control "
                                        placeholder="Enter certificate no"
                                        required
                                    />
                                    <button id="btnPopup" style={{ width: '10%', height: '25%' }} className="send-btnGreen button float-start w-5">Download</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Consumer;
