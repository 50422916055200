import React, { useState } from 'react'

export default function UtilityReactCalender(props) {
  const [focused, setFocused] = useState(false);
  const { errorMessage, onChange, id, ...inputProps } = props;

  const handleFocus = (e) => {
    setFocused(true);
  };
  const [date, setDate] = useState();
  return (
    <>
      <input
        {...inputProps}
        value={date}
        onChange={onChange}
        onBlur={handleFocus}
        focused={focused.toString()}
        type="date"
        required />
      <span>{errorMessage}</span>
    </>
  )
} 